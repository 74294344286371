import React from 'react'
import { useIntl } from 'gatsby-plugin-react-intl'
import { Container, Row, Col } from '../../components/ui/wrapper'
import Text from '../../components/ui/text'
import Image from '../../data/images/impressum.png'

const LegalNoticeArea = () => {
    const intl = useIntl()

    return (
        <Container pt="65px" pb="65px">
            <Row>
                <Col>
                    <Text fontWeight="bold">
                        {intl.formatMessage({
                            id: 'page.legalNotice.text',
                        })}
                    </Text>
                    <img src={Image} alt="impressum" />
                </Col>
            </Row>
        </Container>
    )
}

export default LegalNoticeArea
