import React from 'react'
import { useIntl } from 'gatsby-plugin-react-intl'
import SEO from '../components/seo'
import Layout from '../containers/layout/layout'
import Header from '../containers/layout/header/header-custom'
import Footer from '../containers/layout/footer/footer-custom'
import PageHeader from '../components/pageheader'
import LegalNoticeArea from '../containers/legal-notice-area'

const LegalNoticePage = ({ pageContext, location }) => {
    const intl = useIntl()

    return (
        <Layout location={location}>
            <SEO
                title={intl.formatMessage({
                    id: 'page.legalNotice.title',
                })}
            />
            <Header />
            <PageHeader
                pageContext={pageContext}
                location={location}
                title={intl.formatMessage({
                    id: 'page.legalNotice.title',
                })}
            />
            <LegalNoticeArea />
            <Footer />
        </Layout>
    )
}

export default LegalNoticePage
